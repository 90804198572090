// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, onMessage, getToken } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
    apiKey: "AIzaSyCGs2Joyx5Cod92zGWmXQZiHAwHhysipt8",
    authDomain: "quidapp-a51a4.firebaseapp.com",
    projectId: "quidapp-a51a4",
    storageBucket: "quidapp-a51a4.appspot.com",
    messagingSenderId: "417011058182",
    appId: "1:417011058182:web:d2157485575ae51df72587",
    measurementId: "G-9GDW3FM9TL"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


/*
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, onMessage, getToken } from "firebase/messaging";


var firebaseConfig = {
    apiKey: "AIzaSyCGs2Joyx5Cod92zGWmXQZiHAwHhysipt8",
    authDomain: "quidapp-a51a4.firebaseapp.com",
    databaseURL: 'https://quidapp-a51a4.firebaseio.com',
    projectId: "quidapp-a51a4",
    storageBucket: "quidapp-a51a4.appspot.com",
    messagingSenderId: "417011058182",
    appId: "1:417011058182:web:1be2070951826199f72587",
    measurementId: "G-SC6S2CQPP9"
};

// Initialize Firebase

const firebase = initializeApp(firebaseConfig);
//const analytics = getAnalytics(firebase);


const messaging = getMessaging(app);

window.initFirebaseMessagingRegistration = function () {

    getToken(messaging, { vapidKey: 'BB2f0Lc8i3g3Bu6Be9LEYN6dVAo7PNlslx6ngA9dSZSycSihIvfVxA1WUZ0ET2X-JfM4cHyS7dsX53OHx912ggI'}).then((currentToken) => {
        if (currentToken) {
            var data = { 'token' : currentToken };
            var url = laroute.route('save.token');

            ajaxAsyncCall('POST', url, data, true, function (response) {
                if(validAjaxStatus(response))
                    console.log(response);
                //toastr.success(Lang.get('dictionary.toastr_success'), Lang.get('dictionary.quid_app'));
                else
                    toastr.warning(Lang.get('dictionary.toastr_warning'), Lang.get('dictionary.quid_app'));

            });
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
    });

};


onMessage(messaging, (payload) => {
    console.log(payload);
    const noteTitle = payload.notification.title;
    const noteOptions = {
        body: payload.notification.body,
        icon: payload.notification.image,
    };
    new Notification(noteTitle, noteOptions);
});


if ('serviceWorker' in navigator) {
    window.addEventListener('load', function() {
      navigator.serviceWorker.register('/../firebase-messaging-sw.js');
    });
}

*/
const { constant, result } = require('lodash');

window.ajaxAsyncCall = function (method, url, data, showMessage = false, callback) {

    if(url){
        if(url[0] == '/')
            url = HOST_URL + url;

        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            type: method,
            url: url,
            quietMillis: 100,
            dataType: 'JSON',
            data: data,
            cache: false,
            timeout: 30000,
            async: true,
            beforeSend: function() {
                $('.page-loader').addClass('page-loader-non-block');
                $('.page-loader').css('display', 'flex');
                $('.page-loader').show();
            },
            complete: function(response) {
                $('.page-loader').removeClass('page-loader-non-block');
                $('.page-loader').css('display', 'none');
                $('.page-loader').hide();
                callback(response);
            },
            success: function (response,status,xhr) {
                if(showMessage)
                    toastr.success(Lang.get('dictionary.toastr_success'), Lang.get('dictionary.quid_app'));
            },
            error: function (xhr,status,error) {
                toastr.error(Lang.get('dictionary.toastr_error'), Lang.get('dictionary.quid_app'));
            }
        });
    }

}

window.ajaxAsyncCallXml = function (method, url, data, showMessage = false, callback) {

    if(url){
        if(url[0] == '/')
            url = HOST_URL + url;

        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            type: method,
            url: url,
            quietMillis: 100,
            dataType: 'XML',
            data: data,
            cache: false,
            timeout: 30000,
            async: true,
            beforeSend: function() {
                $('.page-loader').addClass('page-loader-non-block');
                $('.page-loader').css('display', 'flex');
                $('.page-loader').show();
            },
            complete: function(response) {
                $('.page-loader').removeClass('page-loader-non-block');
                $('.page-loader').css('display', 'none');
                $('.page-loader').hide();
                callback(response);
            },
            success: function (response,status,xhr) {
                if(showMessage)
                    toastr.success(Lang.get('dictionary.toastr_success'), Lang.get('dictionary.quid_app'));
            },
            error: function (xhr,status,error) {
                toastr.error(Lang.get('dictionary.toastr_error'), Lang.get('dictionary.quid_app'));
            }
        });
    }

}

window.ajaxAsyncUploadFile = function (url, data, showMessage = false, callback) {

    $.ajax({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        type:'POST',
        url: url,
        data: data,
        contentType: false,
        processData: false,
        quietMillis: 100,
        data: data,
        cache: false,
        timeout: 30000,
        async: true,
        beforeSend: function() {
            $('.page-loader').css('display', 'flex');
            $('.page-loader').show();
        },
        complete: function(response) {
            $('.page-loader').css('display', 'none');
            $('.page-loader').hide();
            callback(response);
        },
        success: function (response,status,xhr) {
            if(showMessage)
                toastr.success(Lang.get('dictionary.toastr_success'), Lang.get('dictionary.quid_app'));
        },
        error: function (xhr,status,error) {
            toastr.error(Lang.get('dictionary.toastr_error'), Lang.get('dictionary.quid_app'));
        }
    });

}

window.ajaxGeoPositionAsyncCall = function (method, url, data, showMessage = false, callback) {

    if(url){
        if(url[0] == '/')
            url = HOST_URL + url;

        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            type: method,
            url: url,
            quietMillis: 100,
            dataType: 'JSON',
            data: data,
            cache: false,
            timeout: 30000,
            async: true,
            beforeSend: function() {

            },
            complete: function(response) {

                callback(response);
            },
            success: function (response,status,xhr) {
                if(showMessage)
                    toastr.success(Lang.get('dictionary.toastr_success'), Lang.get('dictionary.quid_app'));
            },
            error: function (xhr,status,error) {
                toastr.error(Lang.get('dictionary.toastr_error'), Lang.get('dictionary.quid_app'));
            }
        });
    }

}

window.validAjaxStatus = function (response){

    return ( response.status >= 200 && response.status < 300 || response.status === 304 ) ? true : false;

}

$('document').ready(function(){

    require('./messages.js')
    Lang.setLocale($('html').attr('lang'));

    let params = new URLSearchParams(location.search);
    var paramTab = params.get('tab');
    var paramId = params.get('id');

    if(paramTab)
        $('#'+paramTab).trigger('click');

    if(paramId)
        $('#'+paramId).addClass('table-primary');

    $('input.only-numbers').keyup(function(e) {
        if (/\D/g.test(this.value))
            this.value = this.value.replace(/\D/g, '');
    });

    $( "table.datatable" ).each(function() {
        createDatatable($(this));
    });

    $('select.select2').each(function() {
        createSelct2($(this));
    });

    $('input.date-range').each(function() {
        var start = $(this).data('start');
        if(!start)
            start = moment().subtract(29, 'days');

        var end = $(this).data('end');
        if(!end)
            end = moment();

        var min = $(this).data('min');
        var max = $(this).data('max');
        //max = end;

        var data = {
            startDate: start,
            endDate: end,
            minDate: min,
            maxDate: max,
            alwaysShowCalendars: true,
            locale: {
                format: 'DD/MM/yy',
                separator: ' - ',
                applyLabel: Lang.get('dictionary.apply'),
                cancelLabel: Lang.get('dictionary.cancel'),
                fromLabel: Lang.get('dictionary.from'),
                toLabel: Lang.get('dictionary.to'),
                customRangeLabel: Lang.get('dictionary.custom'),
                weekLabel: Lang.get('dictionary.S'),
                daysOfWeek: [Lang.get('dictionary.sun'), Lang.get('dictionary.mon'), Lang.get('dictionary.tue'), Lang.get('dictionary.wed'), Lang.get('dictionary.thu'), Lang.get('dictionary.fri'), Lang.get('dictionary.sat')],
                monthNames: [Lang.get('dictionary.january'), Lang.get('dictionary.february'), Lang.get('dictionary.march'), Lang.get('dictionary.april'), Lang.get('dictionary.may'), Lang.get('dictionary.june'),
                                Lang.get('dictionary.july'), Lang.get('dictionary.august'), Lang.get('dictionary.september'), Lang.get('dictionary.october'), Lang.get('dictionary.november'), Lang.get('dictionary.december')],
                firstDay: 1
            },
            showDropdowns: true
        };

        var today = moment();
        var minDay = moment(min, 'YYYY-MM-DD')

        if( (!min) || minDay < today){
            data['ranges'] = {
                [Lang.get('dictionary.today')]: [moment(), moment()],
                [Lang.get('dictionary.yesterday')]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                [Lang.get('dictionary.last_seven_days')]: [moment().subtract(6, 'days'), moment()],
                [Lang.get('dictionary.last_fourteen_days')]: [moment().subtract(13, 'days'), moment()],
                [Lang.get('dictionary.last_thirty_days')]: [moment().subtract(29, 'days'), moment()],
                [Lang.get('dictionary.this_month')]: [moment().startOf('month'), moment().endOf('month')],
                [Lang.get('dictionary.last_month')]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            };
        }

        $(this).daterangepicker(data);

        var id = $(this).attr('id');
        $('#'+id+'_start_day').val(moment(start, 'DD/MM/YYYY').format('YYYY-MM-DD'));
        $('#'+id+'_end_day').val(moment(end, 'DD/MM/YYYY').format('YYYY-MM-DD'));

    });

    $('table').on('click', 'a.btn-outline-danger', function(e){
        e.preventDefault();
        e.stopPropagation();

        var url = $(this).attr('href');
        var tr = $(this).parents('tr');

        ajaxAsyncCall('DELETE', url, [], true, function (response) {

            if(validAjaxStatus(response)){

                if(typeof customRowUpdate === "function")
                    customRowUpdate(tr);

                tr.remove();
            }
        });

    });


    $('table').on('click', 'button.production-order-status', function(e){
        e.preventDefault();

        var url = $(this).data('url');
        var data = {'status' : $(this).data('value') };
        var btnGroup = $(this).parent();
        var btn = '';

        ajaxAsyncCall('POST', url, data, false, function (response) {

            if(validAjaxStatus(response)){
                var productionOrder = response.responseJSON;
                switch(productionOrder.status) {
                    case constants.production_order_status.accepted:
                        btn = '<button class="btn btn-sm btn-icon btn-primary production-order-status" data-value="'+constants.production_order_status.working+'" data-url="'+url+'"><i class="fas fa-play"></i></button>';
                        break;
                    case constants.production_order_status.rejected:
                        btn = '<button class="btn btn-sm btn-icon btn-outline-danger production-order-status" data-value="" data-url=""><i class="fas fa-times"></i></button>';
                        break;
                    case constants.production_order_status.working:
                        btn = '<button class="btn btn-sm btn-icon btn-warning production-order-status" data-value="'+constants.production_order_status.suspended+'" data-url="'+url+'"><i class="fas fa-pause"></i></button>';
                        btn += '<button class="btn btn-sm btn-icon btn-danger production-order-status" data-value="'+constants.production_order_status.completed+'" data-url="'+url+'"><i class="fas fa-stop"></i></button>';
                        break;
                    case constants.production_order_status.suspended:
                        btn = '<button class="btn btn-sm btn-icon btn-primary production-order-status" data-value="'+constants.production_order_status.working+'" data-url="'+url+'"><i class="fas fa-play"></i></button>';
                        btn += '<button class="btn btn-sm btn-icon btn-danger production-order-status" data-value="'+constants.production_order_status.completed+'" data-url="'+url+'"><i class="fas fa-stop"></i></button>';
                        break;
                    case constants.production_order_status.completed:
                        btn = '<button class="btn btn-sm btn-icon btn-info production-order-status" data-value="'+constants.production_order_status.sent+'" data-url="'+url+'"><i class="fas fa-truck"></i></button>';
                        break;
                    case constants.production_order_status.sent:
                        btn = '<button class="btn btn-sm btn-icon btn-success production-order-status" data-value="" data-url=""><i class="fas fa-check"></i></button>';
                        break;

                    default:
                        // code block
                }
                btnGroup.html(btn);
            }
        });
    });

    $('div').on('click', 'button.opl-status', function(e) {
        e.preventDefault();
        e.stopPropagation();

        var btnClicked = $(this);
        var url = btnClicked.attr('data-url');
        var data = {'status' : btnClicked.attr('data-value') };
        var div = btnClicked.parent();
        var btn = '';

        ajaxAsyncCall('POST', url, data, false, function (response) {

            if(validAjaxStatus(response)){
                btnClicked.remove();
                var opl = response.responseJSON;
                if(div.hasClass('d-flex')){
                    switch(opl.status) {
                        case constants.opl_status.doing:
                            btn = '<button class="btn btn-sm btn-icon btn-success ml-2 opl-status" data-toggle="tooltip" title="'+Lang.get('dictionary.go_to_completed')+'" data-value="'+constants.opl_status.done+'" data-url="'+url+'"><i class="fas fa-check"></i></button>';
                            break;
                        case constants.opl_status.done:
                            btn = '<button class="btn btn-sm btn-icon btn-outline-primary ml-2 opl-status" data-toggle="tooltip" title="'+Lang.get('dictionary.go_to_on_working')+'" data-value="'+constants.opl_status.doing+'" data-url="'+url+'"><i class="fas fa-play"></i></button>';
                            break;

                        default:
                            // code block
                    }
                    div.append(btn);

                    var span = div.children('span');
                    span.html(Lang.get('dictionary.'+opl.status));
                    switch (opl.status) {
                        case constants.opl_status.doing:
                            span.removeClass('label-light-warning').removeClass('label-light-success').addClass('label-light-primary');
                            break;
                        case constants.opl_status.done:
                            span.removeClass('label-light-primary').addClass('label-light-success');
                            break;
                    }
                }

                $('[data-toggle="tooltip"]').tooltip({
                    trigger : 'hover'
                });

            }
        });

    });

    $('div').on('click', 'button.show-details', function(e) {
        e.preventDefault();
        e.stopPropagation();

        let div = $(this).closest('div.card-body').find('div.details');
        if ( div.css('display') == 'none')
            div.show();
        else
            div.hide();
    });

    $('.web-relay-output').on('click', function(e) {

        e.preventDefault();

        var url = $(this).data('url');

        ajaxAsyncCall('GET', url, [], true, function (response) {

            //console.log(response);

            //if(validAjaxStatus(response))
            //    console.log(respone);

        });
    });

});

window.createSelct2 = function(select){

    select.select2({
        width: '100%',
        language: {
            "noResults": function(){
                return Lang.get('dictionary.select2_no_results');
            }
        },
        escapeMarkup: function (markup) {
             return markup;
        },
        sorter: function(data) {
            return data.sort(function(a, b) {
                return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
            });
        }
    });

}

window.createDatatable = function (table){
    var orderColumn = table.data("order-column").split(',');
    var groupColumn = table.data("group-column");
    var sumColumn = table.data("sum-column");
    var hideButtons = table.data("hide-buttons");

    var title = table.data("title");

    if(sumColumn)
        var sumColumnGroup = (groupColumn < sumColumn) ? sumColumn - 1 : sumColumn;
    else
        var sumColumnGroup = '';

    if(!title)
        title = $(document).attr('title');

    var columnFilter = table.data("filter-column");

    if($.fn.DataTable.isDataTable(table)){
        //table.DataTable().destroy();
        var paging = false;
        var searching = false;
    } else {
        var paging = true;
        var searching = true;
    }


    if(orderColumn == "")
        orderColumn = [ 0 , "asc" ];
    else
        orderColumn = [ orderColumn[0] , orderColumn[1] ];

    if(String(columnFilter) != '')
        columnFilter = true;
    else
        columnFilter = false;

    if(String(groupColumn) != ''){
        orderColumn[0] = String(groupColumn);

        var column_defs = {};
        column_defs["visible"] = false;
        column_defs["targets"] = groupColumn;
    } else {
        var column_defs = '';
    }

    table.DataTable({
        language: {
            "decimal":        ",",
            "emptyTable":     Lang.get('dictionary.datatable_no_data_available_in_table'),
            "info":           Lang.get('dictionary.datatable_info'),
            "infoEmpty":      Lang.get('dictionary.datatable_info_empty'),
            "infoFiltered":   Lang.get('dictionary.datatable_info_filtered'),
            "infoPostFix":    "",
            "thousands":      ".",
            "lengthMenu":     Lang.get('dictionary.datatable_length_menu'),
            "loadingRecords": Lang.get('dictionary.datatable_loading_records')+"...",
            "processing":     Lang.get('dictionary.datatable_processing')+"...",
            "search":         Lang.get('dictionary.datatable_search')+":",
            "zeroRecords":    Lang.get('dictionary.datatable_zero_records'),
            "paginate": {
                "first":      Lang.get('dictionary.datatable_first'),
                "last":       Lang.get('dictionary.datatable_last'),
                "next":       Lang.get('dictionary.datatable_next'),
                "previous":   Lang.get('dictionary.datatable_previous')
            },
            "aria": {
                "sortAscending":  Lang.get('dictionary.datatable_sort_ascending'),
                "sortDescending": Lang.get('dictionary.datatable_sort_descending')
            }
        },
        retrieve    : true,
        columnDefs  : [column_defs],
        order       : orderColumn,
        pageLength  : 10,
        lengthMenu  : [10, 25, 50, 100, 250, 500, 1000],
        autoWidth   : true,
        paging      : paging,
        lengthChange: true,
        searching   : searching,
        info        : true,
        autoWidth   : true,
        responsive  : true,
        ordering    : true,
        colReorder  : false,
        columnFilter: columnFilter,
        dom         : '<"row" <"col-lg-4 col-md-12"l> <"col-lg-4 col-md-12"B> <"col-lg-4 col-md-12"fr> > <"row" <"col-12"t> > <"row"<"col-lg-6 col-md-12"i><"col-lg-6 col-md-12"p>>',
        fixedHeader : { header: true, footer: false },
        buttons: [
        /*    {
                extend: 'csvHtml5',
                title: title
            },
        */
            {
                extend: 'pdfHtml5',
                title: title
            },
            {
                extend: 'excelHtml5',
                title: title
            },
            {
                extend: 'print',
                title: title
            },
        ],

        drawCallback: function ( settings )
        {
            if(String(groupColumn)  != ""){
                var api = this.api();
                var rows = api.rows( {page:'current'} ).nodes();
                var last = null;

                api.column(groupColumn, {page:'current'} ).data().each( function ( group, i ) {

                    if ( last !== group ) {
                        if(sumColumnGroup)
                            $(rows).eq( i ).before('<tr class="group"><td colspan="'+sumColumnGroup+'"><h6>'+group+'</h6></td><td></td><td colspan="99"></td></tr>');
                        else
                            $(rows).eq( i ).before('<tr class="group"><td colspan="2"><h6>'+group+'</h6></td><td></td><td colspan="99"></td></tr>');

                        last = group;
                    }
                });

                var total = 0;
                var workHours = 0;
                var tbody = table.children("tbody");

                if(tbody.children("tr").length > 1){

                    tbody.children("tr").each(function() {
                        var tr = $(this);

                        if(String(sumColumnGroup)  != ""){
                            if(tr.hasClass('group')){
                                setColorToGroupRow(tr.prevAll('.group:first'), total, workHours);
                                total = 0;
                            } else {
                                workHours = parseFloat(tr.find('td:eq('+sumColumnGroup+')').data('value'));
                                total += parseFloat(tr.find('td:eq('+sumColumnGroup+')').html());
                            }
                        } else {
                            if(tr.hasClass('group'))
                                tr.addClass('not-calculated');
                        }

                    });

                    if(String(sumColumnGroup) != "")
                        setColorToGroupRow(tbody.children("tr.group:last"), total, workHours);
                }
            }

            $('[data-toggle="tooltip"]').tooltip({
                trigger : 'hover'
            });
        },

        footerCallback: function ( row, data, start, end, display )
        {
            if(sumColumn != ""){
                var api = this.api(), data;

                var indexLastColumn = $(api.column(sumColumn).header()).siblings(":last").index();
                var colspan = (indexLastColumn - sumColumn)+2;
                var total = 0;
                var pageTotal = 0;

                // Total over all pages
                total = api
                    .column( sumColumn )
                    .data()
                    .reduce( function (a, b) {
                        return parseFloat(a) + parseFloat(b);
                    }, 0 );

                // Total over this page
                pageTotal = api
                    .column( sumColumn, { page: 'current'} )
                    .data()
                    .reduce( function (a, b) {
                        return parseFloat(a) + parseFloat(b);
                    }, 0 );

                $(api.column(sumColumn).footer()).html(
                    '<b>Pag: '+(pageTotal.toFixed(2)) +'</b> (Tot: '+ (total.toFixed(2)) +')'
                );

                var td = $(api.column(sumColumn).footer());
                var tr = td.parent('tr');
                var last = td.siblings(":last");
                td.attr('colspan', colspan);

                if(last.index() == indexLastColumn){
                    for (let index = 0; index < colspan-1; index++)
                        tr.find('td:last').remove();
                }

            }
        },

        initComplete: function ()
        {
            if (columnFilter){
                this.api().columns().every( function () {

                    var column = this;
                    var td = $(column.header());
                    td.html('');

                    var select = $('<select style="font-size: small;" class="form-control input-sm"><option value="">'+td.html()+'</option></select>')
                        .appendTo( $(column.header()).empty() )
                        .on( 'change', function () {

                            var val = $.fn.dataTable.util.escapeRegex($(this).val());

                            column
                                .search( val ? '^'+val+'$' : '', true, false )
                                .draw();
                        })
                        .on('click', function(e) {
                            // stop click event bubbling
                            e.stopPropagation();
                        });

                    column.data().unique().sort().each( function ( d, j ) {
                        select.append( '<option value="'+d+'">'+d+'</option>' )
                    });

                });
            }
        },

    });

    $('[data-toggle="tooltip"]').tooltip({
        trigger : 'hover'
    });

    $('[data-toggle="tooltip"]').on('click', function () {
        $(this).tooltip('hide')
    });

    var wrapper = table.parents('.dataTables_wrapper');

    if(hideButtons == 'hide')
        wrapper.find('div.dt-buttons').hide();


    wrapper.find('div.dataTables_filter').addClass('float-right');
    wrapper.find('div.dataTables_paginate').addClass('float-right');

}


function setColorToGroupRow(trGroup, total, workHours)
{
    //trGroup.removeClass('zero').removeClass('not-enough').removeClass('enough');

    workHours = parseFloat(workHours);
    total = parseFloat(total);

    var tdClass = '';
    if(total == 0){
        tdClass = 'zero';
    } else if(total < workHours){
        tdClass = 'not-enough';
    } else {
        tdClass = 'enough';
    }

    trGroup.find('td:eq(1)').html('<button class="btn btn-sm font-weight-bold total '+tdClass+'">'+total.toFixed(2)+'</button>');
    //trGroup.find('td').removeClass('zero').removeClass('not-enough').removeClass('enough');
    //trGroup.find('td').addClass(tdClass);
}


window.changeProgressBar =  function (progressBar, hoursToDo, hoursDone){

    var color = "bg-success";
    var todo = parseFloat(hoursToDo);
    var done = parseFloat(hoursDone);
    var perc = ((100*done)/todo).toFixed(0);

    if(perc > 80 )
        color = "bg-warning";
    if(perc > 100 )
        color = "bg-danger";

    $('#'+progressBar).attr('aria-valuenow', perc);
    if(perc>100)
        $('#'+progressBar).attr('aria-valuemax', perc);
    else
        $('#'+progressBar).attr('aria-valuemax', 100);

    $('#'+progressBar).attr('class', 'progress-bar ' + color);
    $('#'+progressBar).width(perc + '%');
    $('#'+progressBar+'_label').html(perc + '%');
}

window.formatDateToYYYYMMDD = function(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

window.formatDateToDDMM = function(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month].join('/');
}

window.usersImages = function (users, size, textSize){

    var usersImages = '<div class="symbol-group symbol-hover">';

    users.forEach(user => {

        var pieces = user.name.split(" ");
        var code = "XXX";

        if(pieces.length >= 2)
            code = pieces[0].substr(0, 1) + pieces[1].substr(0, 1) + pieces[1].substr(-1);

        var text = code.toUpperCase();
        var color = 'symbol-light';

        usersImages += `<div class="symbol symbol-circle symbol-${size} ${color}">
            <span class="symbol-label text-primary font-size-${textSize}"  data-toggle="tooltip" title="${user.name}">${text}</span>
        </div>`;
    });

    usersImages += '</div>';

    return usersImages;
}

window.regex_escape = function (str) {
    return str.replace(new RegExp('[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\-]', 'g'), '\\$&');
}

window.production_order_code = function(code){

    let shortCode = '';

    if(code){
        shortCode = (code != 'NOT_SET') ? code.substr(2, 2) + '.' + code.substr(5, 1) + '.' + parseInt(code.substr(code.length - 7)) : 'NOT_SET';

        if(code.length > 16)
            shortCode += '-' + parseInt(code.slice(code.length - 4));
    }

    return shortCode;
};

window.setCookie = function(cname, cvalue, exdays){

    const d = new Date();
    d.setTime(d.getTime() + (exdays*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";

}

window.getCookie = function(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}

window.deleteCookie = function(cname, path){

    document.cookie = cname +'=; Path='+path+'; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

}
